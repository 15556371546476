body {
   overflow-x: hidden;
   font-family: "Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
 }

h1, h2, h3, h4, h5, h6 {
  font-family: Rajdhani,"Helvetica Neue",Helvetica,Arial,sans-serif;
  text-transform: uppercase;
  font-weight:bold;
}
a {
  color: #F6BE00;
}
hr{
  border: solid 2px #222;
}
#header{
  background-color: #35373e;
}

.btn{
  font-weight: bold !important;
  text-transform: uppercase !important;
}
.btn-primary.active, .btn-primary:active,
.btn-xl.active, .btn-xl:active, .open .dropdown-toggle.btn-primary,
.open .dropdown-toggle.btn-xl {
  background-image: none !important;
}
.btn-primary {
  color: #fff !important;
  background-color: #C16C18 !important;
  border-color: #C16C18 !important;
  font-weight:bold !important;
}
.btn-primary.active, .btn-primary:active,
.btn-primary:focus, .btn-primary:hover, .open .dropdown-toggle.btn-primary,
.btn-xl.active, .btn-xl:active, .btn-xl:focus,
.btn-xl:hover, .open .dropdown-toggle.btn-xl {
  color: #fff !important;
  background-color: #F6BE00 !important;
  border-color: #F6BE00 !important;
}
.btn-primary.disabled, .btn-primary.disabled.active,
.btn-primary.disabled:active, .btn-primary.disabled:focus,
.btn-primary.disabled:hover, .btn-primary[disabled],
.btn-primary[disabled].active, .btn-primary[disabled]:active,
.btn-primary[disabled]:focus, .btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary, fieldset[disabled] .btn-primary.active,
fieldset[disabled] .btn-primary:active, fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary:hover, .btn-xl.disabled, .btn-xl.disabled.active,
.btn-xl.disabled:active, .btn-xl.disabled:focus, .btn-xl.disabled:hover,
.btn-xl[disabled], .btn-xl[disabled].active, .btn-xl[disabled]:active,
.btn-xl[disabled]:focus, .btn-xl[disabled]:hover, fieldset[disabled] .btn-xl,
fieldset[disabled] .btn-xl.active, fieldset[disabled] .btn-xl:active, fieldset[disabled] .btn-xl:focus,
fieldset[disabled] .btn-xl:hover {
  background-color: #C16C18 !important;
  border-color: #C16C18 !important;
}
.btn-primary .badge, .btn-xl .badge{
  color: #F6BE00 !important;
  background-color: #fff !important;
}
a, a.active, a:active,
a:focus, a:hover, .btn.active,
.btn:active, .btn:active:focus, .btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

#Navigation{
  background-color: #222;
  a, button, .link {
    font-family: Rajdhani,"Helvetica Neue",Helvetica,Arial,sans-serif;
    text-transform: uppercase;
    font-size: 1.05em;
    color: #fff;

  }
  a:hover, button:hover, .link:hover{
    color: #f6be00;
    text-decoration: none;
    cursor: pointer;
  }
  .dropdown-menu{
    background-color: #222;

    .dropdown-item:hover{
      background-color: #222;
    }
  }
}


.fs-70{
  font-size: .7em !important;
}
.fs-75{
  font-size: .75em !important;
}
.fs-90{
  font-size: .9em !important;
}
.fs-110{
  font-size: 1.1em !important;
}
.fs-120{
  font-size: 1.2em !important;
}
.fs-150{
  font-size: 1.5em !important;
}
.fs-160{
  font-size: 1.6em !important;
}
.fs-200{
  font-size: 2em !important;
}